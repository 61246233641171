import { createSelector } from "@reduxjs/toolkit";
import { lenderStateSelector, loanStateSelector, ocrStateSelector } from "./app.selectors";
import { lenderIdSelector } from "src/redux/selectors/lender.selector";

import { JobId } from "aws-sdk/clients/textract";
import { ExtractOutput } from "src/backend/services/ocr/spread-data-aggregation/strategy/data-aggregation-strategy";
import { addToCategory } from "src/utils/helpers";
import { IncomeAssetParts } from "src/backend/services/ocr/abstractions/property";
import { ExtractableDocumentType } from "src/Enums/ExtractableDocumentType";
import { RentRollTableData } from "src/classes/RenderedDocuments/RentRoll";
import { NOIAnalysisProps } from "src/redux/reducers/types";
import { FileReference } from "src/pages/api/documents/ocr/FileReference";
import { Form1040 } from "src/interfaces/TaxFormData/Form1040";
import { TaxFormYear } from "src/interfaces/TaxFormData/TaxFormData";
import { Form1065 } from "src/interfaces/TaxFormData/Form1065";
import { Form1120S } from "src/interfaces/TaxFormData/Form1120s";
import { TaxProperty } from "src/interfaces/TaxFormData/schedules/ScheduleE";
import { CanonicalRentRoll } from "src/models/CanonicalRentRoll";
import spreadConfig from "src/spreads-config";
import { SupportedLenderId } from "src/interfaces/SpreadsConfig/SpreadsConfig";

export const dataNeededSelector = createSelector(
  [ocrStateSelector],
  (ocrState): FileReference[] => ocrState.dataNeeded,
);
export const runningJobsSelector = createSelector(
  [ocrStateSelector],
  (ocrState): Record<JobId | number, FileReference> => ocrState.jobs ?? {},
);
export const allDataSelector = createSelector([ocrStateSelector], (ocrState): ExtractOutput[] =>
  ocrState.allExtracts ? Object.values(ocrState.allExtracts) : [],
);

export const cachedExtractsSelector = createSelector(
  [ocrStateSelector],
  (ocrState): Record<JobId, ExtractOutput> => ocrState.cachedExtracts ?? {},
);

export const currentlyPollingSelector = createSelector(
  [ocrStateSelector],
  (ocrState): boolean => ocrState.isPolling,
);

export const availableUploadIds = createSelector(
  [ocrStateSelector],
  (ocrState): number[] => ocrState.availableUploadIds,
);

export const selectedUploadIdsSelector = createSelector(
  [ocrStateSelector],
  (ocrState): number[] => ocrState.selectedUploadIds,
);
export const historicalDataSelector = createSelector(
  [ocrStateSelector, lenderStateSelector],
  (ocrState, lenderState) =>
    ocrState.allExtracts
      ? Object.values(ocrState.allExtracts).reduce(
          (acc, extractOutput) => {
            const lenderId =
              lenderState.data.find((employee) => employee.employerId)?.employerId || null;
            const lenderSpreadSettings = lenderId
              ? spreadConfig.lenderSettings[lenderId as SupportedLenderId]
              : null;
            const { useLLMForRentRoll } = lenderSpreadSettings || { useLLMForRentRoll: false };
            const extractBase = extractOutput.output;
            const source = extractOutput.source;
            const processIncomeAssetParts = (val: IncomeAssetParts | any) => {
              const year = val.year || new Date().getFullYear() - 1;
              if (
                source === ExtractableDocumentType.MULTI_PROPERTY_RENTROLL ||
                source === ExtractableDocumentType.RENT_ROLL
              ) {
                if (useLLMForRentRoll) {
                  addToCategory(acc, val, year, source);
                  acc.subjectAssets.push(val as CanonicalRentRoll);
                }
                {
                  addToCategory(acc, val, year, source);
                  acc.legacySubjectAssets.push(val as RentRollTableData);
                }
              } else if (
                source === ExtractableDocumentType.PERSONAL_TAX_RETURN ||
                source === ExtractableDocumentType.PERSONAL_TAX_RETURN_PROPERTY
              ) {
                const form1040 = val as Form1040;
                const taxYear = year.toString() as TaxFormYear;
                if (!acc.personalTaxReturns[taxYear]) {
                  acc.personalTaxReturns[taxYear] = [];
                }
                acc.personalTaxReturns[taxYear]?.push(form1040);
              } else if (
                source === ExtractableDocumentType.FORM_1065 ||
                source === ExtractableDocumentType.FORM_1120S ||
                source === ExtractableDocumentType.CORPORATE_TAX_RETURN ||
                source === ExtractableDocumentType.CORPORATE_TAX_RETURN_PROPERTY
              ) {
                const form = val as Form1065 | Form1120S;
                const taxYear = year.toString() as TaxFormYear;
                if (!acc.businessTaxReturns[taxYear]) {
                  acc.businessTaxReturns[taxYear] = [];
                }
                acc.businessTaxReturns[taxYear]?.push(form);
              }
            };
            if (Array.isArray(extractBase)) {
              extractBase.forEach(processIncomeAssetParts);
            } else {
              processIncomeAssetParts(extractBase);
            }
            return acc;
          },
          {
            rentRolls: {},
            personalTaxReturns: {},
            subjectAssets: [],
            businessTaxReturns: {},
            legacyRentRolls: {},
            legacySubjectAssets: [],
          } as NOIAnalysisProps,
        )
      : null,
);

export const candidateSubjectPropertySelector = createSelector(
  [historicalDataSelector, lenderStateSelector],
  (historicalData, lenderState) => {
    const lenderId = lenderState.data.find((employee) => employee.employerId)?.employerId || null;
    const lenderSettings = spreadConfig.lenderSettings[lenderId as SupportedLenderId];
    const { useLLMForRentRoll } = lenderSettings || { useLLMForRentRoll: false };
    if (useLLMForRentRoll) {
      return (
        Object.values(historicalData?.rentRolls || {})
          .flat()
          // Filter out duplicate property Names
          .filter(
            (val, index, self) =>
              self.findIndex((t) => t?.streetAddress === val?.streetAddress) === index,
          ) as CanonicalRentRoll[]
      );
    } else {
      return (
        Object.values(historicalData?.legacyRentRolls || {})
          .flat()
          // Filter out duplicate property Names
          .filter(
            (val, index, self) =>
              self.findIndex((t) => t?.propertyName === val?.propertyName) === index,
          ) as RentRollTableData[]
      );
    }
  },
);

export type SpreadableProperty = CanonicalRentRoll | TaxProperty;
export const candidateAssetSelector = createSelector(
  [historicalDataSelector],
  (historicalData: NOIAnalysisProps | null) => {
    const ptrs = Object.values(historicalData?.personalTaxReturns || {}).flat();
    const btrs = Object.values(historicalData?.businessTaxReturns || {}).flat();
    const rentRolls: CanonicalRentRoll[] = Object.values(
      historicalData?.rentRolls || {},
    ).flat() as CanonicalRentRoll[];
    const legacyRentRolls: RentRollTableData[] = Object.values(
      historicalData?.legacyRentRolls || {},
    ).flat() as RentRollTableData[];

    const personalTaxReturnData = ptrs
      .map((form1040: Form1040) =>
        form1040?.schedules?.scheduleE?.properties?.map((property) => ({
          ...property,
          streetAddress: property.propertyAddress,
        })),
      )
      .flat() as TaxProperty[];

    const businessTaxReturnData = btrs
      .map((form1065: Form1065 | Form1120S) =>
        form1065?.form8825?.propertyData?.map((property) => ({
          ...property,
          streetAddress: property.propertyAddress,
        })),
      )
      .flat() as TaxProperty[];

    const legacyRentRollData = legacyRentRolls.map((legacyRentRoll) => ({
      ...legacyRentRoll,
      streetAddress: legacyRentRoll.propertyName,
    }));

    const candidates = [
      ...personalTaxReturnData,
      ...businessTaxReturnData,
      ...rentRolls,
      ...legacyRentRollData,
    ]
      // Filter out duplicate property Names
      .filter(
        (val, index, self) =>
          self.findIndex((t) => t?.streetAddress === val?.streetAddress) === index,
      ) as CanonicalRentRoll[];

    return candidates.length > 0 ? (candidates as SpreadableProperty[]) : null;
  },
);
